import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import Login from './views/login.vue'
import Layout from './layouts/Layout'

Vue.use(Router)

export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            alias: '/home',
            component: Layout,
            children: [{
                path: '',
                name: 'Calendar',
                component: () => import('./views/Home.vue')
            }]
        },
        {
            path: '/dashboard/contract-rent',
            component: Layout,
            children: [{
                path: '/dashboard/contract-rent',
                name: 'contract-rent-dashboard',
                component: () => import('./views/dashboard/contractRentDashboard.vue'),
                meta: {
                    permissions: [{name: Permissions.REALESTATE_DASHBOARD.name, level: 'rw'}],
                }
            },
                {
                    path: '/dashboard/contract-rent/monthly',
                    name: 'contract-rent-dashboard',
                    component: () => import('./views/dashboard/contractRentMonthlyDashboard.vue'),
                    meta: {
                        permissions: [{name: Permissions.REALESTATE_DASHBOARD.name, level: 'rw'}],
                    }
                }
            ]
        },
        /*{
            path: '/dashboard',
            component: Layout,
            children: [{
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import('./views/dashboard/advertilio_dashboard.vue')
            }]
        },
        {
            path: '/dashboardProperties',
            component: Layout,
            children: [{
                path: '/dashboardProperties',
                name: 'RealEstate Dashboard',
                component: () => import('./views/dashboard/property_dashboard.vue')
            }]
        },
        {
            path: '/packages',
            component: Layout,
            children: [
                {
                    path: '',
                    alias: '',
                    name: 'Pricing',
                    component: () => import('./views/dashboard/pricing.vue')

                }]
        },
        {
            path: '/treasureLive',
            component: Layout,
            children: [{
                path: '/treasureLive',
                name: 'Treasure Live',
                component: () => import('./views/dashboard/treasure_live.vue')
            }]
        },*/
        {
            path: '/packages',
            component: Layout,
            children: [{
                path: '/packages',
                name: 'Pricing',
                component: () => import('./views/dashboard/pricing.vue'),
                meta: {
                    permissions: [{name: Permissions.PRICING.name, level: 'r'}],
                }
            }]
        }, {
            path: '/messages',
            component: Layout,
            children: [{
                path: '/messages',
                name: 'Messages',
                component: () => import('./views/messages/messagesView.vue'),
                meta: {
                    permissions: [{name: Permissions.MESSAGES.name, level: 'rw'}],
                }
            }]
        }, {
            path: '/kanban',
            component: Layout,
            children: [{
                path: '/kanban',
                name: 'Kanban',
                component: () => import('./views/realEstate/kanban/Kanban.vue'),
                meta: {
                    permissions: [{name: Permissions.KANBAN.name, level: 'rw'}],
                }
            }]
        },
        /*{
            path: '/product',
            component: Layout,
            children: [
                {
                    path: '/product',
                    name: 'Product',
                    component: () => import('./views/product/products')
                },
                {
                    path: '/deletedProduct',
                    name: 'Deleted Products',
                    component: () => import('./views/product/deletedProducts')
                }, {
                    path: '/category',
                    name: 'Category',
                    component: () => import('./views/product/categories')
                },
                {
                    path: 'edit/:id',
                    name: 'Edit Product',
                    component: () => import('./views/product/editProduct')
                },
                {
                    path: 'coupon/edit/:id',
                    name: 'Edit Product',
                    component: () => import('./views/product/editCoupon')
                },
                {
                    path: '/coupon',
                    name: 'Coupon',
                    component: () => import('./views/product/vouchers')
                },
            ]
        },*/
        {
            path: '/cleaning',
            component: Layout,
            children: [
                {
                    path: '/cleaning/tasks',
                    name: 'cleaningTask',
                    component: () => import('./views/cleaning/CleaningTaskView.vue'),
                    meta: {
                        permissions: [{name: Permissions.CLEANING.name, level: 'r'}],
                    }
                },
                {
                    path: '/cleaning/history',
                    name: 'cleaningHistory',
                    component: () => import('./views/cleaning/CleaningHistoryView.vue'),
                    meta: {
                        permissions: [{name: Permissions.CLEANING.name, level: 'r'}],
                    }
                },
            ]
        },
        {
            path: '/real_estate',
            component: Layout,
            children: [
                {
                    path: '/treasureRent',
                    name: 'Wohnclub',
                    component: () => import('./views/calendar/CalendarRentSite.vue'),
                    meta: {
                        permissions: [{name: Permissions.TREASURE_RENT.name, level: 'rw'}],
                    }
                },
                {
                    path: '/calendar/time-line/rents',
                    name: 'rent',
                    component: () => import('./views/calendar/TimeLineRentCalendar.vue'),
                    meta: {
                        permissions: [{name: Permissions.TREASURE_RENT.name, level: 'r'}],
                    }
                },
                {
                    path: '/vips',
                    name: 'Wohnclub',
                    component: () => import('./views/realEstate/vipClub/vips'),
                    meta: {
                        permissions: [{name: Permissions.VIP_CLUB.name, level: 'rw'}],
                    }
                },
                {
                    path: '/vip/edit/:id',
                    name: 'Edit Vip Club',
                    component: () => import('./views/realEstate/vipClub/editVip'),
                    meta: {
                        permissions: [{name: Permissions.VIP_CLUB.name, level: 'rw'}],
                    }
                },
                {
                    path: '/buildings',
                    name: 'Buildings',
                    component: () => import('./views/realEstate/building/buildings'),
                    meta: {
                        permissions: [{name: Permissions.BUILDING.name, level: 'rw'}],
                    }
                }, {
                    path: '/messageTemplate',
                    name: 'Messages templates',
                    component: () => import('./views/realEstate/messageTemplate/messageTemplates.vue'),
                    meta: {
                        permissions: [{name: Permissions.BUILDING.name, level: 'rw'}],
                    }
                },
                {
                    path: '/building/edit/:id',
                    name: 'Edit Building',
                    component: () => import('./views/realEstate/building/editBuilding'),
                    meta: {
                        permissions: [{name: Permissions.BUILDING.name, level: 'rw'}],
                    }
                },
                {
                    path: '/banks',
                    name: 'Banks',
                    component: () => import('./views/bank/banks'),
                    meta: {
                        permissions: [{name: Permissions.BANK.name, level: 'r'}],
                    }
                },
                {
                    path: '/bank/edit/:id',
                    name: 'Edit Bank',
                    component: () => import('./views/bank/editBank'),
                    meta: {
                        permissions: [{name: Permissions.BANK.name, level: 'rw'}],
                    }
                },
                /*{
                    path: '/refinances',
                    name: 'Refiances',
                    component: () => import('./views/refinance/refinances')
                },
                {
                    path: '/refinance/edit/:id',
                    name: 'Edit Refinance',
                    component: () => import('./views/refinance/editRefinance')
                },*/
                {
                    path: '/real_estate',
                    name: 'Real estate',
                    component: () => import('./views/realEstate/realEstates'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_ACTIVE.name, level: 'rw'}],
                    }
                },{
                    path: '/exports',
                    name: 'Real estate',
                    component: () => import('./views/realEstate/exports'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_ACTIVE.name, level: 'rw'}],
                    }
                },
                {
                    path: '/deleted_real_estate',
                    name: 'Deleted real estate',
                    component: () => import('./views/realEstate/deletedRealEstates'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE.name, level: 'rw'}],
                    }
                },
                {
                    path: 'edit/:id',
                    name: 'Edit Real estate',
                    component: () => import('./views/realEstate/editRealEstate'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE.name, level: 'rw'}],
                    }
                }, {
                    path: 'edit/:id/:requestId',
                    name: 'Edit Real estate',
                    component: () => import('./views/realEstate/editRealEstate'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE.name, level: 'rw'}],
                    }
                }, /*{
                    path: '/real_estate/bookings',
                    name: 'Edit Real estate',
                    component: () => import('./views/realEstate/realEstatesBookings')
                },*/


                /*{
                    path: '/ksvs',
                    name: 'Ksv',
                    component: () => import('./views/realEstate/ksv/ksvs')
                },
                {
                    path: '/ksv/edit/:id',
                    name: 'Edit Ksv',
                    component: () => import('./views/realEstate/ksv/editKsv')
                },*/ {
                    path: '/real_estate/nuki',
                    name: 'Edit Nuki devices',
                    component: () => import('./views/realEstate/nuki/nukiSmartLocks'),
                    meta: {
                        permissions: [{name: Permissions.NUKI.name, level: 'rw'}],
                    }
                }, {
                    path: '/real_estate/ticket',
                    name: 'Support tickets',
                    component: () => import('./views/realEstate/support/tickets'),
                    meta: {
                        permissions: [{name: Permissions.SUPPORT_TICKET.name, level: 'rw'}],
                    }
                },
                {
                    path: '/ticket/edit/:id',
                    name: 'Edit ticket',
                    component: () => import('./views/realEstate/support/editTicket'),
                    meta: {
                        permissions: [{name: Permissions.SUPPORT_TICKET.name, level: 'rw'}],
                    }
                },
                {
                    path: 'contracts/rent',
                    name: 'Real estate rent',
                    component: () => import('./views/realEstate/contracts/RealEstateRentContracts'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_CONTRACT.name, level: 'rw'}],
                    }
                },
                {
                    path: 'contracts/inquiry',
                    name: 'Real estate inquiry',
                    component: () => import('./views/realEstate/contracts/RealEstateRentContractsInquiry')
                },
                {
                    path: 'contracts/rent/:id',
                    name: 'Edit Real estate rent contract',
                    component: () => import('./views/realEstate/contracts/RealEstateRentContractEdit'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_CONTRACT.name, level: 'rw'}],
                    }
                },
                /*{
                    path: 'contracts/buy',
                    name: 'Real estate buy',
                    component: () => import('./views/realEstate/contracts/RealEstateContracts')
                },
                {
                    path: 'contracts/buy/:id',
                    name: 'Edit Real estate  contract',
                    component: () => import('./views/realEstate/contracts/RealEstateContractEdit')
                },*/
                {
                    path: 'contracts/templates',
                    name: 'Contract templates',
                    component: () => import('./views/realEstate/contracts/ContractTemplates'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_CONTRACT.name, level: 'rw'}],
                    }
                },
                {
                    path: 'contracts/template/edit/:id',
                    name: 'Contract templates',
                    component: () => import('./views/realEstate/contracts/editContractTemplate'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_CONTRACT.name, level: 'rw'}],
                    }
                },
                {
                    path: 'contracts/template/new/:id',
                    name: 'Contract templates',
                    component: () => import('./views/realEstate/contracts/newContractTemplate'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_CONTRACT.name, level: 'rw'}],
                    }
                },
                {
                    path: 'price-labs',
                    name: 'Price labs',
                    component: () => import('./views/realEstate/priceLabs/priceLabsView'),
                    meta: {
                        permissions: [{name: Permissions.REAL_ESTATE_CONTRACT.name, level: 'rw'}],
                    }
                },

               /* {
                    path: 'static-prices',
                    name: 'rent',
                    component: () => import('./views/realEstate/priceLabs/PriceCalendar.vue'),
                    meta: {
                        permissions: [{name: Permissions.TREASURE_RENT.name, level: 'r'}],
                    }
                },*/
            ]
        },
        /* {
             path: '/ccampaign',
             component: Layout,
             children: [
                 {
                     path: '/ccampaign',
                     name: 'Coupon Campaigns',
                     component: () => import('./views/coupon/cCampaigns')
                 },
                 {
                     path: 'edit/:id',
                     name: 'Edit Campaign',
                     component: () => import('./views/coupon/editCcampaign')
                 }]

         },*/
        {
            path: '/whitelabel',
            component: Layout,
            children: [
                {
                    path: '/whitelabel',
                    name: 'Whitelabel',
                    component: () => import('./views/whitelabel/whitelabels'),
                    meta: {
                        permissions: [{name: Permissions.WHITELABEL.name, level: 'rw'}],
                    }
                },
                {
                    path: '/whitelabel/edit/:id',
                    name: 'Edit whitelabel',
                    component: () => import('./views/whitelabel/editWhitelabel'),
                    meta: {
                        permissions: [{name: Permissions.WHITELABEL.name, level: 'rw'}],
                    }
                }]

        },
        /*{
            path: '/databrain',
            component: Layout,
            children: [
                {
                    path: '/databrain',
                    name: 'Databrain',
                    component: () => import('./views/databrain/databrains')
                }]

        },*/
        /*  {
              path: '/ads',
              component: Layout,
              children: [
                  {
                      path: '/ads',
                      name: 'Adverts',
                      component: () => import('./views/ads/videos.vue')
                  },
                  {
                      path: 'campaigns',
                      name: 'Campaigns',
                      component: () => import('./views/campaign/campaigns.vue')
                  },
                  {
                      path: 'campaigns/campaign/:id-:name',
                      name: 'Campaign',
                      component: () => import('./views/campaign/campaign')
                  }, {
                      path: 'strategies',
                      name: 'Strategies',
                      component: () => import('./views/strategies/strategies.vue')
                  }]

          },*/
        /* {
             path: '/statistics',
             component: Layout,
             children: [{
                 path: '/statistics',
                 name: 'Statistics',
                 component: () => import('./views/statistics/statistics.vue')
             },
                 {
                     path: '/advertising',
                     name: 'advertising',
                     component: () => import('./views/statistics/advertising.vue')
                 }]
         },*/
        /* {
             path: '/devices',
             component: Layout,
             children: [
                 {
                     path: '',
                     name: 'Devices',
                     component: () => import('./views/device/devices')
                 },
                 {
                     path: 'device/:id-:name',
                     name: 'Devices',
                     component: () => import('./views/device/device')
                 }
             ]
         },*/
        /*  {
              path: '/campaigns',
              component: Layout,
              children: []
          },*/
        /*{
            path: '/calendar',
            component: Layout,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    name: 'AllCalendar',
                    path: '/report/allcalendar',
                    component: () => import('./views/report/AllCalendar.vue')
                },
                {
                    path: '',
                    name: 'apps-calendar',
                    component: () => import('@/views/calendar/Calendar.vue'),
                },
            ]
        },*/
        {
            path: '/clients',
            component: Layout,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    name: 'Clients',
                    path: '/clients',
                    component: () => import('./views/client/Clients.vue'),
                    meta: {
                        breadcrumb: [{text: 'Clients', active: true,}],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                },
                {
                    name: 'Renters',
                    path: '/renters',
                    component: () => import('./views/client/Renters.vue'),
                    meta: {
                        breadcrumb: [{text: 'Renters', active: true,}],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                },
                {
                    name: 'Owners',
                    path: '/owners',
                    component: () => import('./views/client/Owners.vue'),
                    meta: {
                        breadcrumb: [{text: 'Owners', active: true,}],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                },
                {
                    name: 'Client',
                    path: 'edit/:id',
                    component: () => import('./views/client/editClient.vue'),
                    meta: {
                        breadcrumb: ['clients', '#edit', ':id'],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                },
                /*{
                    name: 'Clients Settings',
                    path: 'settings',
                    component: () => import('./views/client-settings/clientSettings.vue'),
                    meta: {
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    }
                },*/
                {
                    name: 'Clients notes',
                    path: 'notes',
                    meta: {
                        breadcrumb: [{text: 'Clients', to: '/clients'}, {text: 'notes', active: true}],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                    component: () => import('./views/client-notes/clientNotes.vue'),
                },
                {
                    name: 'Clients sms',
                    path: 'sms',
                    meta: {
                        breadcrumb: [{text: 'Clients', to: '/clients'}, {text: 'sms', active: true}],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                    component: () => import('./views/client-sms/clientSms.vue')
                },
                {
                    name: 'Deleted Clients',
                    path: 'deleted',
                    component: () => import('./views/client/DeletedClients.vue'),
                    meta: {
                        breadcrumb: [{text: 'Clients', to: '/clients'}, {text: 'deleted', active: true}],
                        permissions: [{name: Permissions.CLIENT.name, level: 'r'}],
                    },
                }
            ]
        },

        {
            path: '/report',
            component: Layout,
            children: [
                {
                    name: 'Calls',
                    path: 'calls',
                    component: () => import('./views/report/Call.vue')
                },
                {
                    name: 'Monetary Operations',
                    path: 'monetary',
                    component: () => import('./views/monetary/payment/payments'),
                    meta: {
                        permissions: [{name: Permissions.MONETARY.name, level: 'rw'}],
                    }
                },
                {
                    name: 'Monetary invoices',
                    path: 'monetary/invoices',
                    component: () => import('./views/monetary/invoice/invoices.vue'),
                    meta: {
                        permissions: [{name: Permissions.INVOICE.name, level: 'r'}],
                    }
                },
                {
                    name: 'Monetary invoices',
                    path: 'monetary/cashbook',
                    component: () => import('./views/monetary/invoice/cashbook.vue'),
                    meta: {
                        permissions: [{name: Permissions.MONETARY.name, level: 'rw'}],
                    }
                },
                {
                    name: 'Invoice',
                    path: 'monetary/invoice/:id',
                    component: () => import('./views/monetary/invoice/invoice.vue'),
                    meta: {
                        permissions: [{name: Permissions.INVOICE.name, level: 'r'}],
                    }
                },
                {
                    name: 'Orders',
                    path: 'orders',
                    component: () => import('./views/order/orders.vue'),
                    meta: {
                        permissions: [{name: Permissions.ORDER.name, level: 'r'}],
                    }
                },

            ]
        },

        {
            path: '/organization',
            component: Layout,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [{
                name: 'Organization',
                path: '',
                alias: 'organization',
                meta: {
                    breadcrumb: [{text: 'Organization', active: true,}]
                },
                component: () => import('./views/organization/Organizations.vue')
            }, {
                path: 'edit/:id',
                meta: {
                    breadcrumb: [{text: 'Organization', to: '/organization'}, {
                        text: 'edit',
                        active: true
                    }, {text: '[id]', active: true,}],
                    permissions: [{name: Permissions.ORGANIZATION.name, level: 'rw'}],
                },
                component: () => import('./views/organization/OrganizationDetails.vue')
            },
                {
                    name: 'User',
                    path: 'users-new',
                    meta: {
                        breadcrumb: [{text: 'Users', active: true,}],
                        permissions: [{name: Permissions.USER.name, level: 'rw'}],

                    },
                    component: () => import('./views/user/users-list/UsersList')
                },
                {
                    name: 'User',
                    path: 'users',
                    meta: {
                        breadcrumb: [{text: 'Users', active: true,}],
                        permissions: [{name: Permissions.USER.name, level: 'rw'}],
                    },
                    component: () => import('./views/organization/Users.vue')
                },
                {
                    name: 'Branches',
                    path: 'branches',
                    component: () => import('./views/organization/Branches.vue'),
                    meta: {
                        permissions: [{name: Permissions.BUSINESSUNIT.name, level: 'rw'}],
                    }
                },
                {
                    name: 'Affiliate',
                    path: 'affiliate',
                    component: () => import('./views/affiliate/Affiliate.vue'),
                    meta: {
                        permissions: [{name: Permissions.AFFILIATE.name, level: 'rw'}],
                    }
                },
                {
                    name: 'Branches Settings',
                    path: 'branches/settings',
                    component: () => import('./views/organization/BranchesSettings.vue'),
                    meta: {
                        permissions: [{name: Permissions.AFFILIATE.name, level: 'rw'}],
                    }
                }
                ,
                {
                    name: 'Mail Events',
                    path: 'mailevents',
                    component: () => import('./views/organization/MailEvents.vue'),
                    meta: {
                        permissions: [{name: Permissions.MAILEVENT.name, level: 'rw'}],
                    }
                }
                ,
                {
                    name: 'Permissions',
                    path: 'users/permissions',
                    component: () => import('./views/permission/Permissions.vue'),
                    meta: {
                        permissions: [{name: Permissions.PERMISSION.name, level: 'rw'}],
                    }
                }]
        },
       /* {
            path: '/system',
            component: Layout,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    name: 'Tracker',
                    path: 'tracker',
                    component: () => import('./views/system/Tracker.vue'),
                    meta: {
                        permissions: [{name: Permissions.TRACKER.name, level: 'rw'}],
                    }
                }

            ]
        },*/

        {
            path: '/chat',
            component: Layout,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    name: 'Chat',
                    path: '',
                    component: () => import('./views/chat/chatView.vue')
                }

            ]
        },


        {
            path: '/login',
            name: 'login',
            component: Layout,
            children: [
                {

                    path: '',
                    component: () => import('./views/login'),
                    meta: {
                        layout: 'full',
                        resource: 'Auth',
                        redirectIfLoggedIn: true,
                    },
                }
            ],

        }, /*{
            path: '/ads',
            component: Layout,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    name: 'Ads-videos',
                    path: '',
                    component: () => import('./views/ads/videos')
                },

            ]
        },*/
        {
            path: '/error-404',
            alias: ['/404'],
            name: 'error-404',
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
            component: Layout,
            children: [{
                path: '',
                name: 'Error - Page not found',
                component: () => import('./views/error/Error404'),
            }]
        }, {
            path: '/not-authorized',
            name: 'not-authorized',
            meta: {
                layout: 'full',
                resource: 'Auth',
                action: 'read',
            },
            component: Layout,
            children: [{
                path: '',
                name: 'Error - Page not found',
                component: () => import('./views/error/NotAuthorized'),
            }]
        },
        {
            path: '*',
            redirect: 'error-404',
        },

    ]
})

import $ from 'jquery'
import Permissions from "@/model/permissions/permissionNames";

router.beforeEach((to, from, next) => {

    $('#loading')
        .show()
    // redirect to login page if not logged in and trying to access a restricted page
    store.dispatch('user/canAccess', {to})
        .then(function (path) {
            $('#loading')
                .hide()

            if (path !== undefined && to.fullPath === path) {
                return next()
            }
            next(path)
        })
        .catch(function (path) {
            $('#loading')
                .hide()

            if (path !== undefined) {
                return next('/login?returnUrl=' + to.fullPath)
            }

            next(path)
        })
})

